import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/teoria-toc-i-spe.jpeg";
import * as styles from "./index.module.css";
import { Link } from "gatsby";



const Nowasprzedaz = ({ data }) => {
  return (
    <Artykul
      title="Co tak naprawdę ogranicza Twoją sprzedaż? CEO Questy w “Nowej Sprzedaży”"
      keywords={["CEO Questy w Nowej Sprzedaży"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Co tak naprawdę ogranicza Twoją sprzedaż? CEO Questy w “Nowej Sprzedaży”"
      metaTitle="Co tak naprawdę ogranicza Twoją sprzedaż?"
      metaDescription="CEO Questy w “Nowej Sprzedaży” ✅ Co ogranicza Twoją sprzedaż? • Questy - dostawcy oprogramowania dla biznesu"
    >
      <br />
      <br />
      <h2>
        <strong> Co tak naprawdę ogranicza Twoją sprzedaż?</strong>{" "}
      </h2>
      <center>
        {" "}
        <p>
          {" "}
          Łukasz Tadyszak – CEO Questy – odpowiada na łamach artykułu w „Nowej
          Sprzedaży”{" "}
        </p>{" "}
      </center>
      <p>
        W październikowym wydaniu magazynu „Nowa Sprzedaż” ukazał się{" "}
        <strong> artykuł naszego CEO – Łukasza Tadyszaka</strong>. Materiał
        dotyczy skalowania sprzedaży relacyjnej i koncepcji TOC, czyli teorii
        ograniczeń.
      </p>
      <br />
      <br />
      <h4> Koncepcja TOC i SPE w sprzedaży </h4>

      <p>
        Artykuł skupia się na dobrych praktykach{" "}
        <strong> wdrażania koncepcji SPE</strong> (Sales Process Engineering).
        Omówione zostały takie kwestie, jak{" "}
        <strong> identyfikowanie wąskich gardeł, podział pracy</strong> i ról
        czy <strong> weryfikowanie ograniczeń sprzedaży</strong>. Zapraszamy
        serdecznie do przeczytania całego artykułu, który znaleźć można pod
        <strong>
          {" "}
          <a href="https://www.nowa-sprzedaz.pl/artykul/skalowanie-modelu-sprzedazy-relacyjnej-z-wykorzystaniem-toc-co-tak-naprawde-ogranicza-twoja-sprzedaz/">
            tym linkiem.
          </a>{" "}
        </strong>
      </p>
      <br />
      <br />
      <h4>
        <strong> Czego dowiesz się z artykułu? </strong>{" "}
      </h4>
      <p>
        <ul>
          <li>
            {" "}
            Dlaczego poziom sprzedaży zależy nie tylko od zaangażowania i
            umiejętności handlowców?{" "}
          </li>
          <li> Jak zidentyfikować i wykorzystać wolne zasoby ludzkie? </li>
          <li> Czym jest Sales Process Engineering? </li>
          <li> Czy warto wdrożyć SPE? </li>
          <li> Jak wdrożyć SPE? </li>
          <li> Jak technologia może wspierać dobre praktyki SPE? </li>
          <li> Jakim organizacjom potrzebne jest SPE i TOC? </li>
        </ul>
      </p>
      <br />
      <br />
      <h4> Dobre praktyki ze wsparciem technologii </h4>
      <p>
        Tematyka <strong> wsparcia sprzedaży i optymalizowania pracy</strong>{" "}
        całego przedsiębiorstwa, którą porusza artykuł, jest niezwykle bliska
        firmie Questy. Projekty, które na co dzień realizujemy, pomagają wielu
        polskim firmom w prowadzeniu{" "}
        <strong> płynnej sprzedaży i obsługi ich klientów</strong>. Największe
        korzyści biznesowe można osiągnąć dzięki{" "}
        <strong> synergii technologii i dobrych praktyk zarządzania</strong>.
        Takie połączenie to idealny sposób na stały i stabilny wzrost sprzedaży,
        przy jednoczesnym zachowaniu wysokiej jakości obsługi klientów.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system CRM w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i wejść na wyższy poziom sprzedaży
            </Link>
          </div>
          </div>
    </section>

    </Artykul>
  );
};

export default Nowasprzedaz;
